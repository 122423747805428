import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './Especialidade.module.css';
import { useAssociadoContext } from '../../useAssociadoContext';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { useFileContext } from '../../useFileContext';
import Button from '../Forms/Button';
import getFromS3Signed from '../ServerSide/getFromS3Signed';

const Especialidade = () => {
  const { id } = useParams();
  const { especialidadesGer, docsGer, especialidade, setEspecialidade } =
    useFileContext();
  const [docs, setDocs] = useState<any | undefined>();

  useEffect(() => {
    function definirEspecialidade() {
      const especialidadeAtual = especialidadesGer?.filter(
        (item: any) => item.id == id,
      );
      setEspecialidade(especialidadeAtual);
    }

    async function definirDocs() {
      const docsAtuais = docsGer?.filter(
        (item: any) => item.id_especialidade == id,
      );
      const objectsSigneds = await getFromS3Signed(docsAtuais);
      setDocs(objectsSigneds);
    }
    definirEspecialidade();
    definirDocs();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Especialidade"
        description="Área do associado"
      />
      {especialidade && <TituloAssociado titulo={especialidade[0].descricao} />}
      <div className={styles.divEspecialidade}>
        {docs && docs.length > 0 && especialidade ? (
          docs.map((item: any, index: any) => (
            <Link key={index} to={item.url} target="_blank">
              {item.Key.replace(
                'especialidades/' + especialidade[0].descricao + '/',
                '',
              ).replace('.pdf', '')}
            </Link>
          ))
        ) : (
          <p className={styles.alerta}>
            Não existem documentos para esta especialidade ainda.
          </p>
        )}
      </div>
    </section>
  );
};

export default Especialidade;
