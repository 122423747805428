import React, { useEffect, useState } from 'react';
import styles from './GuiasConv.module.css';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { useAssociadoContext } from '../../useAssociadoContext';
import { useUserContext } from '../../UserContext';
import {
  GET_CIRCULARES_CONVENIO,
  GET_GUIAS_CONVENIO,
  GET_INFOS_CONVENIO,
} from '../../api';
import SubTituloAss from '../Helper/SubTituloAss';
import { Link } from 'react-router-dom';
import Button from '../Forms/Button';
import getFromS3Signed from '../ServerSide/getFromS3Signed';

const GuiasConv = () => {
  const { convenio } = useAssociadoContext();
  const [guias, setGuias] = useState([]);
  const { loading, setLoading } = useUserContext();

  useEffect(() => {
    async function getGuias() {
      try {
        setLoading(true);
        const { url, options } = GET_GUIAS_CONVENIO({
          id_convenio: convenio[0].id,
        });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.message);
        const objects = await getFromS3Signed(json);
        setGuias(objects as never[]);
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
    getGuias();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title={'Área Gerencial - Guias ' + convenio[0].nome}
        description="Área Gerencial"
      />
      <TituloAssociado titulo={'guias - ' + convenio[0].nome} />
      <div className={styles.divCirculares}>
        {guias.length > 0 ? (
          guias?.map((item: any, index) => (
            <Link to={item.url} target="blank" key={index}>
              {item.Key.replace('guias/', '').replace('.pdf', '')}
            </Link>
          ))
        ) : (
          <p className={styles.alerta}>
            Não existem guias para esse convênio ainda.
          </p>
        )}
        {}
      </div>
    </section>
  );
};

export default GuiasConv;
