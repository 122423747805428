import React, { useEffect, useState } from 'react';
import styles from './CircularesAss.module.css';
import TituloAssociado from '../Helper/TituloAssociado';
import { useUserContext } from '../../UserContext';
import { Link } from 'react-router-dom';
import SubTituloAss from '../Helper/SubTituloAss';
import Head from '../Helper/Head';

const CircularesAss = () => {
  const { circulares, circularesFilt } = useUserContext();
  const [anos, setAnos] = useState<any[]>();

  useEffect(() => {
    function getYears() {
      const anos: any[] = [];
      circulares?.map((item) => {
        const ano: any = parseInt(item.Key.substr(14, 4));
        if (!anos.includes(ano) && ano) {
          anos.push(ano);
        }
      });
      anos.sort((a, b) => b - a);

      return anos;
    }
    setAnos(getYears());
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <TituloAssociado titulo="Circulares" />
      <Head
        title="Área do Associado - Circulares"
        description="Área do associado - Circulares"
      />
      <div className={styles.divCirculares}>
        {anos?.map((ano, index) => (
          <div className={styles.divAno} key={index}>
            <SubTituloAss subTitulo={'circulares - ano ' + ano} />
            {circularesFilt?.map((item, index) =>
              index !== 0 && new RegExp('^.{14}' + ano).test(item.Key) ? (
                <Link to={item.url} target="blank" key={index}>
                  {'Nº ' +
                    item.Key.replace('circulares/', '')
                      .replace('.pdf', '')
                      .replace(/\+/g, ' ')}
                </Link>
              ) : (
                ''
              ),
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default CircularesAss;
