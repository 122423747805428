import React, { useRef } from 'react';
import styles from './Grade.module.css';
import associados from './Associados';
import { Link } from 'react-router-dom';

interface Data {
  url?: string;
  url_logo: string;
  link_clinica: string;
}

interface GradeProps {
  data: Data[];
}

const Grade: React.FC<GradeProps> = ({ data }) => {
  const myElementRef = useRef(null);

  return (
    <section
      ref={myElementRef}
      className={`${styles.containerPrincipal} animeLeft`}
      id="clientes"
      aria-label="Nossos clientes"
    >
      <ul>
        {data?.map(({ url, link_clinica }, index) => (
          <li key={index}>
            <Link
              className={styles.link}
              to={
                link_clinica.includes('http://') ||
                link_clinica.includes('https://')
                  ? link_clinica
                  : 'https://' + link_clinica
              }
              target="_blank"
            >
              <img src={url} alt={link_clinica} />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Grade;
