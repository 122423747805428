import React, { useEffect, useState } from 'react';
import styles from './Formulario.module.css';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { Link } from 'react-router-dom';
import getFromS3Signed from '../ServerSide/getFromS3Signed';

const FormularioGuias = () => {
  const [linkForm, setLinkForm] =
    useState<({ Key: string; url: string } | null)[]>();
  const keyFormulario = [
    {
      nome_arquivo:
        'formulario-entrega-guias/FORMULÁRIO DE ENTREGA DE GUIAS.doc',
      url: '',
    },
  ];
  async function getFormulario() {
    const link = await getFromS3Signed(keyFormulario);
    setLinkForm(link);
  }

  useEffect(() => {
    getFormulario();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Formulário de entrega de guias"
        description="Área do associado"
      />
      <TituloAssociado titulo="Formulário de entrega de guias" />
      <div className={styles.divFormulario}>
        {linkForm && linkForm[0] && (
          <Link to={linkForm[0].url} target="_blank">
            Clique aqui para obter o formulário de entrega de guias
          </Link>
        )}
      </div>
    </section>
  );
};

export default FormularioGuias;
