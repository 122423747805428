import React, { useEffect, useState } from 'react';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import styles from './ManuaisLinksGer.module.css';
import ListFiles from '../ServerSide/findFromS3';
import { ObjectList } from 'aws-sdk/clients/s3';
import { Link, useParams } from 'react-router-dom';

const ManuaisLinksGer = () => {
  const [preenchimento, setPreenchimento] = useState<(Object | null)[]>();
  const [manuais, setManuais] = useState<(Object | null)[]>();
  const { param } = useParams();

  useEffect(() => {
    async function buscaManuais() {
      const dataPreenchimento = await ListFiles(
        'manuais/' + param + '/preenchimento/',
        '',
      );
      const dataManuais = await ListFiles('manuais/' + param + '/manuais/', '');
      setManuais(dataManuais);
      setPreenchimento(dataPreenchimento);
    }
    buscaManuais();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Tabelas Gerais"
        description="Área do associado"
      />
      <TituloAssociado titulo="Tabelas Gerais" />
      <div className={styles.divTabelas}>
        <div className={styles.divContainerLinks}>
          <h2>Manuais</h2>
          <div className={styles.divLinks}>
            {manuais &&
              manuais.map((item: any, index: any) =>
                index !== 0 ? (
                  <Link to={item.url} target="_blank" key={index}>
                    {item.Key.replace('manuais/medicos/manuais/', '')
                      .replace('.pdf', '')
                      .replace('manuais/demais/manuais/', '')}
                  </Link>
                ) : (
                  <></>
                ),
              )}
          </div>
        </div>
        <div className={styles.divContainerLinks}>
          <h2>Preenchimento das guias</h2>
          <div className={styles.divLinks}>
            {preenchimento &&
              preenchimento.map((item: any, index: any) =>
                index !== 0 ? (
                  <Link to={item.url} target="_blank" key={index}>
                    {item.Key.replace('manuais/medicos/preenchimento/', '')
                      .replace('.pdf', '')
                      .replace('manuais/demais/preenchimento/', '')}
                  </Link>
                ) : (
                  <></>
                ),
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManuaisLinksGer;
