import React, { useEffect, useRef, useState } from 'react';
import styles from './Associados.module.css';
import Head from '../Helper/Head';
import Grade from '../Helper/Grade';
import associados from '../Helper/Associados';
import { GET_LOGOS } from '../../api';
import { useUserContext } from '../../UserContext';
import ListFiles from '../ServerSide/findFromS3';
import getFromS3Signed from '../ServerSide/getFromS3Signed';

const Associados = () => {
  const myElementRef = useRef(null);
  const [logos, setLogos] = useState<any>();
  const { loading, setLoading } = useUserContext();

  useEffect(() => {
    async function getLogos() {
      try {
        setLoading(true);
        const { url, options } = GET_LOGOS();
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error('Deu erro na consulta das logos');
        const logosAut = await getFromS3Signed(json);
        setLogos(logosAut);
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    getLogos();
  }, []);

  return (
    <>
      <Head title="Associados" description="Todos os associados da AMAI" />

      <section
        className={`${styles.containerPrincipal} animeLeft`}
        ref={myElementRef}
      >
        <h1>Nossos Associados</h1>
        {!loading && <Grade data={logos} />}
      </section>
    </>
  );
};

export default Associados;
