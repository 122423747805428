import React from 'react';
import styles from './Unicred.module.css';
import unicred from '../../Assets/unicred-banner.png';
import { Link } from 'react-router-dom';

const Unicred = () => {
  return (
    <section className={styles.containerPrincipal}>
      <div className={styles.divPrincipal}>
        <Link to="https://wa.me/5561996781068" target="blank">
          <img src={unicred} alt="Banner Unicred" />
        </Link>
      </div>
    </section>
  );
};

export default Unicred;
