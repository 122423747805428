import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './EspecialidadeGer.module.css';
import { useAssociadoContext } from '../../useAssociadoContext';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { useFileContext } from '../../useFileContext';
import Button from '../Forms/Button';
import { ReactComponent as Delete } from '../../Assets/delete.svg';
import { useUserContext } from '../../UserContext';
import { DELETE_DOC_ESPECIALIDADE, GET_ESPECIALIDADES } from '../../api';
import Modal from 'react-modal';
import getFromS3Signed from '../ServerSide/getFromS3Signed';

const EspecialidadeGer = () => {
  const { id } = useParams();
  const { convenios, convenio, setConvenio } = useAssociadoContext();
  const {
    setEspecialidadesGer,
    especialidadesGer,
    setDocsGer,
    docsGer,
    especialidade,
    setEspecialidade,
  } = useFileContext();
  const { loading, setLoading, tokenGer } = useUserContext();
  const [docs, setDocs] = useState<any | undefined>();
  const [mensagem, setMensagem] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [idDoc, setIdDoc] = useState(0);

  async function getEspecialidades() {
    try {
      setLoading(true);
      const { url, options } = GET_ESPECIALIDADES(tokenGer);
      const response = await fetch(url, options);
      const { especialidades, docs } = await response.json();
      if (!response.ok)
        throw new Error('Erro ao tentar trazer as especialidades');
      setEspecialidadesGer(especialidades);
      setDocsGer(docs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteDoc() {
    try {
      setLoading(true);
      setModalOpen(false);
      const { url, options } = DELETE_DOC_ESPECIALIDADE(tokenGer, {
        id: idDoc,
      });
      const response = await fetch(url, options);
      const { message } = await response.json();
      setMensagem(message);
      if (!response.ok) throw new Error(message);
      getEspecialidades();
      setModalOpen2(true);
    } catch (error) {
      console.log('Erro no deleteCircular: ' + error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    function definirEspecialidade() {
      const especialidadeAtual = especialidadesGer?.filter(
        (item: any) => item.id == id,
      );
      setEspecialidade(especialidadeAtual);
    }

    async function definirDocs() {
      const docsAtuais = docsGer?.filter(
        (item: any) => item.id_especialidade == id,
      );
      const objectsSigneds = await getFromS3Signed(docsAtuais);
      setDocs(objectsSigneds);
    }
    definirEspecialidade();
    definirDocs();
  }, [especialidadesGer]);

  function abrirModal(id: any) {
    setModalOpen(true);
    setIdDoc(id);
    console.log(docsGer);
    const doc: any = docs?.filter((item: any) => item.id == id);
    setMensagem(
      'Confirma a EXCLUSÃO do documento ' +
        doc[0].Key?.replace(
          'especialidades/' + especialidade[0].descricao + '/',
          '',
        ).replace('.pdf', '') +
        '? Essa operação não poderá ser desfeita!',
    );
  }

  function fecharModal() {
    setModalOpen(false);
    setModalOpen2(false);
  }

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Especialidade"
        description="Área do associado"
      />
      <Modal
        className={styles.modal}
        isOpen={modalOpen}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <div className={styles.botoes}>
          <Button descricao="excluir" classe="submit" onClick={deleteDoc} />
          <Button descricao="cancelar" classe="cancel" onClick={fecharModal} />
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        isOpen={modalOpen2}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <Button descricao="fechar" classe="main" onClick={fecharModal} />
      </Modal>
      {especialidade && (
        <div className={styles.divTitulo}>
          <TituloAssociado titulo={especialidade[0].descricao} />
          <Link to="novo-doc-especialidade">
            <Button descricao="+ cadastrar documento" classe="submit" />
          </Link>
        </div>
      )}
      <div className={styles.divEspecialidade}>
        {docs && docs.length > 0 && especialidade ? (
          docs.map((item: any, index: any) => (
            <div className={styles.doc}>
              <Link key={index} to={item.url} target="_blank">
                {item.Key.replace(
                  'especialidades/' + especialidade[0].descricao + '/',
                  '',
                ).replace('.pdf', '')}
              </Link>
              <Delete
                className={styles.svg}
                onClick={() => abrirModal(item.id)}
                title="Excluir"
              />
            </div>
          ))
        ) : (
          <p className={styles.alerta}>
            Não existem documentos para esta especialidade ainda.
          </p>
        )}
      </div>
    </section>
  );
};

export default EspecialidadeGer;
