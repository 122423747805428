import React, { useEffect, useState } from 'react';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import styles from './TabelasGer.module.css';
import ListFiles from '../ServerSide/findFromS3';
import { ObjectList } from 'aws-sdk/clients/s3';
import { Link } from 'react-router-dom';
import Button from '../Forms/Button';

const TabelasGer = () => {
  const [amb, setAmb] = useState<(Object | null)[]>();
  const [cbhpm, setCbhpm] = useState<(Object | null)[]>();
  const [portes, setPortes] = useState<(Object | null)[]>();

  useEffect(() => {
    async function buscaTabelas() {
      const dataAmb = await ListFiles('tabelas/amb/', '');
      const dataCbhpm = await ListFiles('tabelas/cbhpm/', '');
      const dataPortes = await ListFiles('tabelas/portes/', '');
      setAmb(dataAmb);
      setCbhpm(dataCbhpm);
      setPortes(dataPortes);
    }
    buscaTabelas();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Tabelas Gerais"
        description="Área do associado"
      />
      <div className={styles.divTitulo}>
        <TituloAssociado titulo="Tabelas gerais" />
        <Link to="nova-tabela">
          <Button descricao="+ cadastrar tabela" classe="submit" />
        </Link>
      </div>
      <div className={styles.divTabelas}>
        <div className={styles.divContainerLinks}>
          <h2>
            Tabelas AMB/92 e de referências {'('}de para TUSS{')'}
          </h2>
          <div className={styles.divLinks}>
            {amb &&
              amb.map((item: any, index: any) =>
                index !== 0 ? (
                  <Link to={item.url} target="_blank" key={index}>
                    {item.Key.replace('tabelas/amb/', '').replace('.pdf', '')}
                  </Link>
                ) : (
                  <></>
                ),
              )}
          </div>
        </div>
        <div className={styles.divContainerLinks}>
          <h2>Tabelas CBHPM</h2>
          <div className={styles.divLinks}>
            {cbhpm &&
              cbhpm.map((item: any, index: any) =>
                index !== 0 ? (
                  <Link to={item.url} target="_blank" key={index}>
                    {item.Key.replace('tabelas/cbhpm/', '').replace('.pdf', '')}
                  </Link>
                ) : (
                  <></>
                ),
              )}
          </div>
        </div>
        <div className={styles.divContainerLinks}>
          <h2>Tabelas de portes</h2>
          <div className={styles.divLinks}>
            {portes &&
              portes.map((item: any, index: any) =>
                index !== 0 ? (
                  <Link to={item.url} target="_blank" key={index}>
                    {item.Key.replace('tabelas/portes/', '').replace(
                      '.pdf',
                      '',
                    )}
                  </Link>
                ) : (
                  <></>
                ),
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TabelasGer;
